* {
  box-sizing: border-box;
}

:root {
  --main-color: #7616f4;
  --main-accent: #f5a02f;
  --dark-text: #1a0536;
  --background-color: #F9F9F9;
}

body, html, h1, h2, h3, h4, p, button {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  width: 100%;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  background-color: var(--background-color);
  color: var(--dark-text);
  font-size: 12px;
  font-family: 'Ubuntu', sans-serif;
}

h1 {
  font-family: 'Jost', sans-serif;
  font-weight: 600;
  font-size: 4rem;
  letter-spacing: 0.5rem;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
}

/* mobile */

@media (max-width: 1200px) {
  h1 {
    font-size: 3rem;
  }
}
@media (max-width: 750px) {
  h1 {
    font-size: 2rem;
    letter-spacing: 0.25rem;
  }  
}



/***********/
/* HEADER */
/***********/

.header--nav {
  position: fixed;
  top: 0;
  background-color: var(--background-color);
  width: 100%;
  height: 6vh;
  box-shadow: 1px 3px 2px -2px var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.nav--link {
  color: var(--dark-text);
  text-decoration: none;
  margin-left: 7vw;
  font-size: 1.25rem;
  font-family: 'Jost', sans-serif;
  font-weight: 300;
  letter-spacing: 0.25rem;
}

.nav--link:visited {
  color: var(--dark-text);
}

.nav--link:active {
  color: var(--main-accent);
}

.nav--link:hover:not(.-home, .-resume) {
  box-shadow: 0px 2px var(--main-accent);
}

.nav--link.-home {
  margin-right: auto;
  font-size: 1.75rem !important;
}

.header--home-char:hover{
  color: var(--main-accent);
}

.nav--link.-resume {
  padding: 3px 10px;
  margin-right: 7vw;
  color: var(--dark-text);
  border: solid 2px var(--main-accent);
  border-radius: 15px;
  background-color: #f59f2f34;
}

.nav--link.-resume:hover {
  background-color: var(--main-accent);
}

/* header-mobile */

.header--nav-mobile {
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: rgba(245, 245, 245, 0.808);
  backdrop-filter: blur(20px);
  width: 100%;
}

.nav--hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
}

.nav--hamburger > div {
  background-color: var(--dark-text);
  border-radius: 20px;
  width: 50px;
  height: 5px;
  margin: 3px 10px 2px 0px;
}

.nav--hamburger-menu {
  position: fixed;
  background-color: #6a14dc;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  right: 0;
  height: 100vh;
  width: 75vw;
  transform: translateX(75vw);
  transition: transform 500ms;
}

.nav--hamburger-menu.-open {
  transform: translateX(0);
}

.hamburger-bot, .hamburger-mid, .hamburger-top {
  transition: transform 500ms, rotate 500ms, opacity 500ms;
}

.hamburger-bot.-open {
  transform: rotate(0.125turn) translate(0px, -13px);
}

.hamburger-top.-open {
  transform: rotate(-0.125turn) translate(0px, 13px);
}

.hamburger-mid.-open {
  transform: translate(-50px, 0);
  opacity: 0;
}

.nav--link.-mobile:not(.-home) {
  color: var(--background-color);
  font-size: 1.5rem;
  margin: 0;
}

.nav--link.-resume.-mobile {
  background-color: #6a14dc;
  border: 2px solid var(--main-accent);
  padding: 5px 25px;
  margin-top: -5px;
}



/********/
/* HERO */
/********/

.hero {
  height: 100vh;
}

.hero--title-section {
  position: fixed;
  z-index: -1;
  padding-top: 250px;
}

.hero--name {
  margin-left: 160px;
  font-size: 6rem;
}

.hero--introduction {
  color: var(--main-accent);
  font-size: 1.35rem;
  letter-spacing: 0.3rem;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin-left: 160px;
}

.hero--description {
  color: var(--dark-text);
  font-size: 1.7rem;
  margin-top: 15px;
  width: 450px;
  line-height: 150%;
  letter-spacing: -0.05rem;
  margin-left: 160px;
}

.hero--underline {
  width: 500px;
  margin-left: 100px;
  margin-top: -20px;
  }

.hero--wave {
  width: 100vw;
  position: absolute;
  bottom: 0px;
  z-index: -1;
}


/* hero-mobile */

@media (max-width: 1200px) {

  .hero {
    min-height: 100vh;
    background-position: 100% 100%;
    background-size:90%;
    display: flex;
    justify-content: center;

  }

  .hero--title-section {
    padding-top: 60px;
    padding-left: 0;
    width: 500px;
  }

  .hero--name {
    font-size: 4rem;
    margin-left: 125px;
  }

  .hero--introduction {
    margin-left: 110px;
  }

  .hero--underline {
    width: 300px;
    margin-left: 100px;
  }

  .hero--description {
    font-size: 1.3rem;
    text-overflow: wrap;
    margin-top: 15px;
    margin-left: 125px;
    width: 275px;
  }
}


/*********/
/* ABOUT */
/*********/

.about {
  background-color: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15vw;
  color: var(--background-color);
  background: linear-gradient(var(--main-color), #6a14dc, var(--main-color), #842df5);
}

.about--text {
  font-size: 1.5rem;
  margin: 5vh 5vw;
  font-family: 'Lato', sans-serif;
  letter-spacing: -0.01rem;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
  line-height: 40px;
}

.about--title {
  margin-top: 8vh;
}

.skills {
  min-height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.skills > h1 {
  color: var(--main-color);
  margin-top: 45px;
}

.skills--icon {
  height: 100px;
  width: 100px;
  margin: 100px 60px;
}

@media (min-width: 1250px) {
  .skills--icon.html:hover, .skills--icon.html.flipped {
    filter: brightness(0) saturate(100%) invert(37%) sepia(61%) saturate(1638%) hue-rotate(344deg) brightness(91%) contrast(94%);
  }
  
  .skills--icon.css:hover, .skills--icon.css.flipped {
    filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(3002%) hue-rotate(224deg) brightness(86%) contrast(108%);
  }
  
  .skills--icon.js:hover, .skills--icon.js.flipped {
    filter: brightness(0) saturate(100%) invert(83%) sepia(56%) saturate(654%) hue-rotate(356deg) brightness(102%) contrast(94%);
  }
  
  .skills--icon.react:hover, .skills--icon.react.flipped {
    filter: brightness(0) saturate(100%) invert(74%) sepia(48%) saturate(4460%) hue-rotate(145deg) brightness(102%) contrast(103%);
  }
  
  .skills--icon.node:hover, .skills--icon.node.flipped {
    filter: brightness(0) saturate(100%) invert(79%) sepia(58%) saturate(462%) hue-rotate(36deg) brightness(85%) contrast(89%);
  }  
}

/* about-mobile */

@media (max-width: 780px) {
  
  .about {
    padding: 0 10vw;
  }

  .about--text {
    font-size: 1.2rem;
    letter-spacing: 0.01rem;
    line-height: 27px;
  }

  .skills--container {
    display: grid;
    margin: 20px 0 20px 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .skills--icon {
    margin: 0;
  }
}
  
@media (min-width: 750px) and (max-width: 1200px) {

  .skills--container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 100px;
  }


  .skills--icon {
    margin: 0;
  }
}
  

/************/
/* PROJECTS */
/************/

.projects, .projects--container, .projects--project{
  display: flex;
  align-items: center;
}

.projects {
  padding-top: 10vh;
  min-height: 100vh;
  background: var(--main-color);
  color: var(--dark-text);
  flex-direction: column;
}

.projects--title {
  margin-bottom: 15vh;
  color: var(--background-color);
}

.projects--container {
  justify-content: center;
}

.projects--project {
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  color: var(--dark-text);
  width: 400px;
  height: 400px;
  background-color: var(--background-color);
  margin: 0px 40px;
  font-size: 2rem;
  text-decoration: none;

  transition: box-shadow 250ms;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 12px -6px rgba(0,0,0,0.11); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 12px -6px rgba(0,0,0,0.11);
}

.projects--project:hover {
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 32px 12px rgba(0,0,0,0.34); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 32px 12px rgba(0,0,0,0.34);
}

.projects--project > h4 {
  font-size: 28px;
}

.projects--project > p {
  margin: 5% 0 0 0;
  font-size: 1.25rem;
  font-family: 'Open Sans', sans-serif;
}

.projects--project > a {
  text-decoration: none;
  border: 2px solid var(--background-color);
  background-color: var(--main-color);
  border-radius: 5px;
  color: var(--background-color);
  width: 150px;
  min-height: 30px;
  padding: 5px 0;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
}

.projects--project > a:hover {
  border: 2px solid var(--main-accent);
}

.line {
  background-color: var(--main-accent);
  width: 150px;
  height: 3px;
  border: 1px solid var(--main-accent);
  border-radius: 100px;
}

/* projects-mobile */

@media (max-width: 1250px) {

  .projects {
    margin-top: 0;
    padding-bottom: 40px;
  }

  .projects--title {
    margin-bottom: 5vh;
    font-size: 10;
  }

  .projects--container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .projects--project {
    width: 300px;
    height: 450px;
    margin: 0;
  }

  .projects--project > h4{
    font-size: 1.75rem;
  }
}

/***********/
/* CONTACT */
/***********/

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact--title {
  margin-top: 50px;
  margin-bottom: 20px;
  color: var(--main-color);
}

.contact--subtitle {
  font-size: 2rem;
  margin-bottom: 75px;
}

.contact--subtitle-email {
  border-bottom: 2px solid #f59f2f77;
  text-decoration: none;
  color: var(--dark-text);
}

.contact--subtitle-email:hover {
  border-bottom: 2px solid var(--main-accent);
  cursor: pointer;
}

.contact--form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
}

.contact--form-input {
  width: 20vw;
  height: 3vh;
  margin-right: 1vw;
  padding: 20px;
  border: 2px solid var(--background-color);
  transition: border 500ms;
  border-radius: 10px;
  background-color: rgb(233, 230, 230);
  font-family: "Ubuntu", sans-serif;
  font-size: 1.25rem;
  outline-color: var(--main-color);
}

.contact--form-input:hover {
  border: 2px solid #7616f44f;
}

.contact--form-input.-message {
  width: 41vw;
  height: 18vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  resize: none;
}

.contact--form-button {
  border: 2px solid var(--background-color);
  border-radius: 10px;
  background-color: var(--main-color);
  color: var(--background-color);
  padding: 10px;
  height: 50px;
  width: 200px;
  margin-bottom: 10vh;
  cursor: pointer;
  font-size: 1.25rem;
  font-family: "Ubuntu", sans-serif;
  transition: border 250ms
}

.contact--form-button:hover {
  border: 2px solid var(--main-accent);
}

.contact--form-top {
  display: flex;
}

.error- {
  height: 20px;
}

.error-.-message {
  margin-bottom: -15px;
  margin-top: 10px;
}

/* contact-mobile */

@media (max-width: 850px) {

  .contact {
    min-height: 100vh;
  }
  
  .contact--subtitle {
    font-size: 1.25rem;
    margin: 0 10px 10px 10px;
  }

  .contact--form-top {
    display: grid;
    grid-template-columns: 1fr;
  }

  .contact--form-input, .contact--form-input.-message {
    width: 300px;
  }
}


/**********/
/* FOOTER */
/**********/

.footer {
  background-color: var(--main-color);
  height: 75px;
  display: flex;

}

.footer--text-container {
  margin: 15px auto 10px 25px;
}

.footer--text-container > p {
  color: var(--background-color);
  font-size: 1rem;
}

.footer--creator {
  margin-bottom: 5px;
}

.footer--link {
  color: #f5a02f;
}

.footer--shortcut-img {
  width: 35px;
  height: 35px;
}

.footer--shortcut {
  margin: 20px 20px 0 0;
}

/* footer-mobile */

@media (max-width: 700px) {

  .footer--text-container {
    margin: 12px auto 5px 25px;
  }

  .footer--text-container > p {
    font-size: 0.8rem;
  }

}